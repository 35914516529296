import React from 'react'
import {
  Layout,
  Navbar,
  Box,
  Container,
  Footer,
  Reveal,
  Flex,
  Input,
  Text,
  Textarea,
  Button,
  Loader,
} from '../components'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { injectIntl, IntlShape } from 'gatsby-plugin-intl'
import { InlineWidget } from 'react-calendly'

// Custom components

const SendButton = styled(Button)`
  width: 10em;
`

// Main component

export const query = graphql`
  query {
    topBackgroundImage: file(relativePath: { eq: "top_background.jpg" }) {
      ...getImage
    }
  }
`

interface IContactPageProps {
  data: any
  intl: IntlShape
}

interface IContactPageState {
  name: string
  email: string
  message: string

  fetching: boolean
  success: boolean
  error: boolean
}

class ContactPage extends React.Component<
  IContactPageProps,
  IContactPageState
> {
  state = {
    name: '',
    email: '',
    message: '',

    fetching: false,
    success: false,
    error: false,
  }

  _sendEmail() {
    const { name, email, message } = this.state
    const body = { name, email, message }

    // Start fetching
    this.setState({
      fetching: true,
    })

    fetch('/send', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) =>
        this.setState({
          success: res.status === 200,
          error: res.status !== 200,
          fetching: false,
        }),
      )
      .catch((_) =>
        this.setState({
          error: true,
          fetching: false,
        }),
      )
  }

  render() {
    const { props, state } = this

    return (
      <Layout
        backgroundImage={`url(${props.data.topBackgroundImage.publicURL})`}
        backgroundSize='80% auto'
        backgroundPosition='bottom center'
      >
        <>
          <Navbar />

          <Container>
            <Box
              padding='8em 0em 0em 0em'
              paddingLg='6em 2em 0em 2em'
              paddingSm='6em 0em 0em 0em'
            >
              <Reveal>
              <Box textAlign='center'>
                <Text

                  color='white'>
                  The company is currently inactive, so as of today, we don't take any new meetings.
                  <br />
                  <br />
                  Thanks for your interest.
                </Text>
                </Box>
              </Reveal>
            </Box>

            <Box padding='15em 0em 8em 0em' paddingLg='11em 0em 8em 0em'>
              <Reveal>
                <Footer />
              </Reveal>
            </Box>
          </Container>
        </>
      </Layout>
    )
  }
}

export default injectIntl(ContactPage)
